// @ts-nocheck
import React, { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Assets from "../../client/assets";

import styles from "./AuthWeb.module.scss";
import stylesInput from '../../components/form/Input.module.scss';

import classnames from "classnames/bind";

import { Modal, Button } from "react-bootstrap";
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';

import {getDecimalsCount, shiftedBy, formatNumber} from "../utils";
import { getConfigByDomain } from "../../client/client";

const cn = classnames.bind(styles);

const SUCCESS = 0;
const CANCEL_ALL_ORDERS = 6;
const BURN_WALLETS = 7;
const BURN_EMPTY_WALLETS = 8;
const BURN_USER_ID = 9;

//update statuses
const NEED_UPDATE = 5;
const UPD_CANCEL_ORDERS = 10;
const UPD_WALLETS = 11;
const UPD_EMPTY_WALLETS = 12;
const UPD_REBIND_WALLETS = 13;
const UPD_FLEX_CLIENT = 14;

export const PreAuthModal = (props: any) => {
  const {onOk, show, removeUserState } = props;

  const [error, setError] = useState('');

  useEffect(()=>{
  }, [props.show]);

  let text = '';
  let success = false;
  const intStatus = removeUserState?.status ? parseInt(removeUserState.status) : undefined;
  if (intStatus === undefined) {
    text = 'Recovering gas...';
  } else if (intStatus === CANCEL_ALL_ORDERS) {
    text = 'Cancelling orders...';
  } else if (intStatus === BURN_WALLETS) {
    text = 'Burning wallets...';
  } else if (intStatus === BURN_EMPTY_WALLETS) {
    text = 'Burning empty wallets...';
  } else if (intStatus === BURN_USER_ID) {
    text = 'Burning user id...';
  } else if (intStatus === SUCCESS) {
    text = 'Success';
    success = true;
  } else if (intStatus === NEED_UPDATE) {
    text = 'Updating...';
  } else if (intStatus === UPD_CANCEL_ORDERS) {
    text = 'Updating: cancelling orders...';
  } else if (intStatus === UPD_WALLETS) {
    text = 'Updating wallets...';
  } else if (intStatus === UPD_EMPTY_WALLETS) {
    text = 'Updating empty wallets...';
  } else if (intStatus === UPD_REBIND_WALLETS) {
    text = 'Updating: rebinding wallets...';
  } else if (intStatus === UPD_FLEX_CLIENT) {
    text = 'Updating flex client...';
  }

  const size = "sm";
  const className = cn(
    `circle-${size}`,
    {"success": success, "progress": !success}
  );

  return (
    <Modal
      id="rm-user-modal"
      show={show}
      onHide={()=>onOk()}
      keyboard={false}
      dialogClassName={cn("flex-modal")}
      contentClassName="border-0 p-0"
      centered
    >
      <div className="flex-modal d-flex flex-column align-items-center justify-content-center p-3 rounded text-white" style={{minHeight: 300, backgroundColor: '#161A1E'}}>
        <div className="d-flex flex-column align-items-center justify-content-center">
          <div className="d-flex flex-row align-items-center mt-2">
            <div className={className}>
              {success ?
                <CheckIcon /> :
                <CircularProgress color="inherit" style={size === 'sm' ? {width:18, height: 18} : {width:24, height: 24}}/>
              }
            </div>
            <small className="ms-2">{text}</small>
          </div>
        </div>
        {success &&
          <Button
            className={cn("mt-3 w-100 btn-action")}
            style={{height: 56, backgroundColor: '#4259CF', borderColor: '#4259CF'}}
            onClick={onOk}
          >
            OK
          </Button>
        }
      </div>
    </Modal>
  );
}
