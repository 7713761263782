// @ts-nocheck
import callback from "./abi";

import { Debots } from "./client";
import DebotClient from "./client";
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import Utils from "./utils";
import { ElectronUtils } from './ElectronUtils';

const browser = import("debot-browser");

//update statuses
const NEED_UPDATE = 5;
const UPD_CANCEL_ORDERS = 10;
const UPD_WALLETS = 11;
const UPD_EMPTY_WALLETS = 12;
const UPD_REBIND_WALLETS = 13;
const UPD_FLEX_CLIENT = 14;

export default class Auth {
  public static async disconnect() {
    const debotClient = await DebotClient.get();
    debotClient.disconnect();
  }

  public static async updateDebot(authResult, cb):Promise<any> {
    console.log(`Calling update...\n`);
    let result = {status: undefined, cache: undefined};

    while (parseInt(result.status) !== SUCCESS) {
      const intStatus = result.status ? parseInt(result.status) : undefined;
      if (intStatus === undefined) {
        cb && cb({status: NEED_UPDATE});
        result = await Auth.update(authResult?.flexClient, authResult?.userId);
        //result.status = `${SUCCESS}`; //DEBUG!!!
      } else if (intStatus === UPD_CANCEL_ORDERS) {
        cb && cb(result);
        result = await Auth.cancelAllOrders(result.cache);
      } else if (intStatus === UPD_WALLETS) {
        cb && cb(result);
        result = await Auth.updateWallets(result.cache);
      } else if (intStatus === UPD_EMPTY_WALLETS) {
        cb && cb(result);
        result = await Auth.updateEmptyWallets(result.cache);
      } else if (intStatus === UPD_REBIND_WALLETS) {
        cb && cb(result);
        result = await Auth.updateRebindWallets(result.cache);
      } else if (intStatus === UPD_FLEX_CLIENT) {
        cb && cb(result);
        result = await Auth.updateFlexClient(result.cache);
      }
      console.log('update result => ', result);
      if (parseInt(result.status) >= 20) break;
    }
    cb && cb(result);
    return result;
  }

  public static async authorize(cb?: any, authObj?: any, deployValue?: any): Promise<any | undefined> {
    console.log('authorize call....');
    const debotClient = await DebotClient.get();
    let webSurf;
    try {
      webSurf = await debotClient.getExtension();
    } catch (err) {
      cb(null, err);
      return;
    }

    console.log('authorize call.... webSurf =', webSurf)
    if (!webSurf) {
      cb(null, "Extension isn't connected");
      return;
    }

    const authData = {flexClient: authObj?.flexClient, userId: authObj?.userId, userIdAddress: authObj?.userIdAddress};

    return new Promise<string | undefined>(async (resolve, reject) => {
      browser.then(() => {
        let value = null;
        const status = authObj ? authObj.status : undefined;

        switch (status) {
          case '1':
            Auth.deployFlexClientSign(authObj.cache).then(res=>({previousAuthState: authObj, ...authData, ...res, extension: webSurf})).then(cb).catch(err => cb(null, err));
            return;
          case '2':
            Auth.deployFlexClient(authObj.cache, deployValue).then(res=>({previousAuthState: authObj, ...authData, ...res, extension: webSurf})).then(cb).catch(err => cb(null, err));
            return;
          case '3':
            Auth.deployUserId(authObj.cache).then(res=>({previousAuthState: authObj, ...authData, ...res, extension: webSurf})).then(cb).catch(err => cb(null, err));
            return;
          case '4':
            Auth.reBindWallets(authObj.cache).then(res=>({previousAuthState: authObj, ...authData, ...res, extension: webSurf})).then(cb).catch(err => cb(null, err));
            return;
          default:
            Auth.auth().then(res=>({previousAuthState: null, ...res, extension: webSurf})).then(cb).catch(err => cb(null, err));
            return;
        }
      }, error => {
        reject();
      });
    });
  }

  //auth manifest
  private static buildAuthManifest (
    func: any,
    args: any
  ): JSON {
    return JSON.parse(`{
      "version": 0,
      "debotAddress": "${Debots.get('auth')!.address}",
      "initMethod": "${func}",
      "initArgs": ${args},
      "quiet": true,
      "autoApprove": ["ApproveOnChainCall"],
      "chain": [],
      "abi": ${callback.authExtAbi}
    }`);
  }

  //auth debot methods
  private static async auth():Promise<any> {
    const debotClient = await DebotClient.get();
    console.log(`Calling auth...\n`);
    const manifest = Auth.buildAuthManifest("auth", `{}`);
    console.log(manifest);
    return await (await browser).run_browser((await debotClient.authBrowserHandle), manifest)
  }

  private static async deployUserId(cache):Promise<any> {
    const debotClient = await DebotClient.get();
    console.log(`Calling deployUserId...\n`);
    const manifest = Auth.buildAuthManifest("deployUserId", `{"cache": "${cache}"}`);
    console.log(manifest);
    return await (await browser).run_browser((await debotClient.authBrowserHandle), manifest)
  }

  private static async deployFlexClientSign(cache):Promise<any> {
    const debotClient = await DebotClient.get();
    console.log(`Calling deployFlexClientSign...\n`);
    const manifest = Auth.buildAuthManifest("deployFlexClientSign", `{"cache": "${cache}"}`);
    console.log(manifest);
    return await (await browser).run_browser((await debotClient.authBrowserHandle), manifest)
  }

  private static async deployFlexClient(cache, value):Promise<any> {
    const debotClient = await DebotClient.get();
    console.log(`Calling deployFlexClient...\n`);
    const manifest = Auth.buildAuthManifest("deployFlexClient", `{"cache": "${cache}", "value": "${value}"}`);
    console.log(manifest);
    return await (await browser).run_browser((await debotClient.authBrowserHandle), manifest)
  }

  private static async reBindWallets(cache):Promise<any> {
    const debotClient = await DebotClient.get();
    console.log(`Calling reBindWallets...\n`);
    const manifest = Auth.buildAuthManifest("reBindWallets", `{"cache": "${cache}"}`);
    console.log(manifest);
    return await (await browser).run_browser((await debotClient.authBrowserHandle), manifest)
  }

  //UPDATE manifest
  private static buildUpdateManifest (
    func: any,
    args: any
  ): JSON {
    return JSON.parse(`{
      "version": 0,
      "debotAddress": "${Debots.get('update')!.address}",
      "initMethod": "${func}",
      "initArgs": ${args},
      "quiet": true,
      "autoApprove": ["ApproveOnChainCall"],
      "chain": [],
      "abi": ${callback.updateAbi}
    }`);
  }

  private static async update(flexClient, userId):Promise<any> {
    const debotClient = await DebotClient.get();
    console.log(`Calling update...\n`);
    const manifest = Auth.buildUpdateManifest("update", `{"flexClient": "${flexClient}", "userId": "${userId}"}`);
    console.log(manifest);
    return await (await browser).run_browser((await debotClient.updateBrowserHandle), manifest)
  }

  private static async cancelAllOrders(cache):Promise<any> {
    const debotClient = await DebotClient.get();
    console.log(`Calling update:cancelAllOrders...\n`);
    const manifest = Auth.buildUpdateManifest("cancelAllOrders", `{"cache": "${cache}"}`);
    console.log(manifest);
    return await (await browser).run_browser((await debotClient.updateBrowserHandle), manifest)
  }

  private static async updateWallets(cache):Promise<any> {
    const debotClient = await DebotClient.get();
    console.log(`Calling update:updateWallets...\n`);
    const manifest = Auth.buildUpdateManifest("updateWallets", `{"cache": "${cache}"}`);
    console.log(manifest);
    return await (await browser).run_browser((await debotClient.updateBrowserHandle), manifest)
  }

  private static async updateEmptyWallets(cache):Promise<any> {
    const debotClient = await DebotClient.get();
    console.log(`Calling update:updateEmptyWallets...\n`);
    const manifest = Auth.buildUpdateManifest("updateEmptyWallets", `{"cache": "${cache}"}`);
    console.log(manifest);
    return await (await browser).run_browser((await debotClient.updateBrowserHandle), manifest)
  }

  private static async updateRebindWallets(cache):Promise<any> {
    const debotClient = await DebotClient.get();
    console.log(`Calling update:updateRebindWallets...\n`);
    const manifest = Auth.buildUpdateManifest("rebindWallets", `{"cache": "${cache}"}`);
    console.log(manifest);
    return await (await browser).run_browser((await debotClient.updateBrowserHandle), manifest)
  }

  private static async updateFlexClient(cache):Promise<any> {
    const debotClient = await DebotClient.get();
    console.log(`Calling update:updateFlexClient...\n`);
    const manifest = Auth.buildUpdateManifest("updateFlexClient", `{"cache": "${cache}"}`);
    console.log(manifest);
    return await (await browser).run_browser((await debotClient.updateBrowserHandle), manifest)
  }
}
