import React, { Dispatch, useState, useRef, useEffect, ReactNode } from "react";
import { Modal, Button } from "react-bootstrap";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';

import cn from "classnames";
import { isMobile } from "react-device-detect";

import Main from "./main";
import Content from "./content";
import { Header, Overlay, Icon, Panel } from "../components";
import { Authenticate, AuthWeb, AssetsWeb, ErrorModal, PreAuthModal, Onboarding } from "./auth";
import Auth from "../client/poochy";
import Recover from "../client/recover";

import GA from "../ga";
import FlexAPI from '../flex_charts/api/flexAPI';
import { setAuthorised } from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
//import { useTheme } from "../controllers/useTheme";

import { ActionTypes, RootState } from "../types";
import {getDecimalsCount, formatNumber, shiftedBy} from "./utils";

const NEED_UPDATE = 5;

const Router = () => {
  const dispatch = useDispatch<Dispatch<ActionTypes>>();
  const [errorAuth, setErrorAuth] = useState<any>(false);
  const [errorAssets, setErrorAssets] = useState<any>(false);
  const [errorRecover, setErrorRecover] = useState<any>(false);
  const [errorRemoveUser, setErrorRemoveUser] = useState<any>(false);

  const [showAuthModal, setShowAuthModal] = useState<boolean>(false);
  const [showAssetsModal, setShowAssetsModal] = useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [showPreAuthModal, setShowPreAuthModal] = useState<boolean>(false);
  const [showOnboarding, setShowOnboarding] = useState<boolean>(false);


  const [preAuthState, setPreAuthState] = useState(null);
  const [assetsMode, setAssetsMode] = useState<string>('deposit');

  const location:string = useLocation().pathname.split('/').filter(Boolean)[0];

  const authFlowCallback = async (authResult: any, authError: any) => {
    console.log('authFlowCallback called with authResult, authError =', authResult, authError);

    if (authResult && parseInt(authResult?.status) === NEED_UPDATE) {
        handleUpdate(authResult);
        return;
    }

    if (authError || authResult && parseInt(authResult?.status) > 4 || !authResult) {
      const errorMessage = authError ? `error: ${authError.toString()}` :
        authResult?.status ? `error status: ${authResult?.status}` :
        'unknown auth error';

      setErrorAuth(authResult ? {operation: 'auth', ...authResult} : errorMessage);
      return;
    }

    dispatch(setAuthorised({...authorized, ...authResult}));

    const needUserAction = (
      authResult?.status === '2' ||
      authResult?.status === '0' ||
      (authResult?.status === '3' && authResult?.previousAuthState === null)
    );
    if (!needUserAction) {
      Auth.authorize(authFlowCallback, authResult);
    }
    //status == '2' interrupts flow and wait for deploy value
    //status == '3' AND previousAuthState == undefined interrupts flow and wait for user choice:
    //re-auth or recover gas
  };

  const startAuthFlow = async () => {
    setErrorAuth(false);
    setShowAuthModal(true);
    Auth.authorize(authFlowCallback);
  }

  const handleOnboarding = () => {
    setShowOnboarding(true);
  }

  const handleConnectSurf = () => {
    startAuthFlow();
  }

  const handleRecoverGas = async () => {
    const flexClient = authorized ? authorized?.flexClient : '';
    const dst = authorized?.extension?.address;
    const flexClientBalance = (window as any).totalGas?.flexClient || 0;
    const amountToWithdraw = flexClientBalance - 0.5;
    const amount = amountToWithdraw > 0 ? shiftedBy(amountToWithdraw, 9) : '';

    if (amountToWithdraw <= 0) {
      setErrorRecover({operation: 'recoverGas', status: '55', flexClient: flexClient}); //low balance
      return;
    }

    if (flexClient && dst && amount) {
      try {
        const state = await Recover.withdrawFromFlexClient(flexClient, dst, amount);
        if (state?.status !== '0') {
          setErrorRecover({operation: 'recoverGas', status: state?.status, flexClient: flexClient});
        }
      } catch (err) {
        setErrorRecover(`recover gas error: ${err}`);
      }
    }
  }

  const handleUpdate = (authResult: any) => {
    Auth.updateDebot(authResult, (state: any) => {
      console.log('update state', state);
      if (state && parseInt(state.status) >= 20) {
        setErrorRemoveUser({operation: 'update', ...state});
        setShowPreAuthModal(false);
        return;
      }
      setPreAuthState({operation: 'update', ...state});
      setShowPreAuthModal(true);
    }).catch(err => {
      setErrorRemoveUser(`update error: ${err.toString()}`);
      setShowPreAuthModal(false);
    });
  }

  const handleRemoveUser = () => {
    Recover.removeUser((state: any) => {
      console.log('removeUser state', state);
      if (state && parseInt(state.status) >= 20) {
        setErrorRemoveUser({operation: 'removeUser', ...state});
        setShowPreAuthModal(false);
        return;
      }
      setPreAuthState(state);
      setShowPreAuthModal(true);
    }).catch(err => {
      setErrorRemoveUser(`removeUser error: ${err.toString()}`);
      setShowPreAuthModal(false);
    });
  }

  const onPreAuthOk = () => {
    setShowPreAuthModal(false);
    startAuthFlow();
  }

  const onAuthError = () => {
    setShowAuthModal(false);
    setErrorAuth(false);
    dispatch(setAuthorised(undefined));
  }

  const onAssetsError = (err: any) => {
    setErrorAssets(err);
    //setShowAssetsModal(true);
  }

  const onAuthOk = async (action: string) => {
    setShowAuthModal(false);
    if (action === 'createUser') {
      Auth.authorize(authFlowCallback, authorized);
    } else if (action === 'recoverGas') {
      handleRecoverGas();
      //interrup auth flow and set auth undefined
      onAuthError();
    }
  }

  const onDeploy = async (enteredValue: any) => {
    console.log('onDeploy');
    Auth.authorize(authFlowCallback, authorized, enteredValue);
  }

  const { authorized } = useSelector((state: RootState) => state.dashboard);
  console.log('authorized =>', authorized);

  const showAssets = (mode: string) => {
    setAssetsMode(mode);
    setShowAssetsModal(true);
  }

  const mainPage = <Main
    showModal={false}
    handleShow={showAssets}
    setModalUrl={()=>{}}
    handleConnectSurf={handleOnboarding}
  />;

  return (<>
    <div className={cn("ws-app", useLocation!().pathname.split('/').filter(Boolean)[0], {"isMobile": isMobile, "index-page": !location})}>
      <Header
        showModal={false}
        showModalUpdateAuth={false}
        toggleModal={()=>{}}
        handleConnectSurf={handleOnboarding}
        handleRemoveUser={handleRemoveUser}
        handleRecoverGas={handleRecoverGas}
      />
        <main>
          <Routes>
            <Route path="/legal-notes/:id" element={<Content />} />
            <Route path="/terminate" element={<Navigate to='/'/>} />
            <Route path="/" element={mainPage} />
            <Route path="/index.html" element={mainPage} />
          </Routes>
        </main>
        <AuthWeb authorized={authorized} show={showAuthModal} onDeploy={onDeploy} onOk={onAuthOk} onError={onAuthError} error={/*errorAuth*/false} />
        <AssetsWeb authorized={authorized} show={showAssetsModal} mode={assetsMode} onError={onAssetsError} onOk={()=>setShowAssetsModal(false)} />
        <PreAuthModal preAuthState={preAuthState} show={showPreAuthModal} onOk={onPreAuthOk} />
        <Onboarding handleConnectSurf={handleConnectSurf} show={showOnboarding} onOk={()=>setShowOnboarding(false)} />

        {!!errorAssets && <ErrorModal show={!!errorAssets} error={errorAssets} onOk={()=>setErrorAssets(false)} />}
        {!!errorRecover && <ErrorModal show={!!errorRecover} error={errorRecover} onOk={()=>setErrorRecover(false)} />}
        {!!errorRemoveUser && <ErrorModal show={!!errorRemoveUser} error={errorRemoveUser} onOk={()=>setErrorRemoveUser(false)} />}
        {!!errorAuth && <ErrorModal show={!!errorAuth} error={errorAuth} onOk={onAuthError} />}
      </div>
    </>
  );
};

export default Router;
