// @ts-nocheck
import {
    Address,
    ConnectResponse,
    ProviderRpcClient,
    hasSurfKeeperProvider
} from '@eversurf/surfkeeper-provider';

import callback from "./abi";

import {
  Debot,
 } from "../interfaces";

 import { ElectronUtils } from './ElectronUtils';

//import * as browser from "debot-browser";
const browser = import("debot-browser");
export const rpc = new ProviderRpcClient();

export const chartsUrl = 'https://flex-charting.web.app';

const DEX_TON_SURF = 'dex.ton.surf'; //test
const TRADE_FLEXDEX_FI = 'trade.flexdex.fi'; //trade
const STAGE_FLEXDEX_FI = 'stage.flexdex.fi'; //stage
const FLEX_TON_LIVE = 'flex.ton.live'; //test
const ELECTRON = 'ELECTRON'; //ELECTRON

const AUTH_DEBOT = 1;
const TRADE_DEBOT = 2;
const EXT_AUTH_DEBOT = 3; // for extenstion
const EXT_ASSETS_DEBOT = 4; // for extension
const EXT_TRADE_DEBOT = 5; // for extension
const RECOVER_DEBOT = 6;
const EXT_UPDATE_DEBOT = 7;

const configs = {
  [ELECTRON]: {
    endpoint: 'mainnet.evercloud.dev/9032ecfe98924075ae833da11735b480',//'prod.flex.everos.dev',
    authDebotId: AUTH_DEBOT,
    tradeDebotId: TRADE_DEBOT,
    authDebot: '0:d0568a66f2e67e16fc2ae49f21b109f7116f484ae401fc93f640a232ddea329f',
    tradeDebot: '0:1d875fefaaca191972013a3221ccb266499e5feb4355c24a5eaad7e75ad1aa7e',
    alias: 'mainnet',
    fee: 1.0015,
    enableMarketMode: true,
    enableCustomKeys: true,
    pairsDisabled: ['USDC/USDT'],
    authDisabled: false,
    defaultPairTicker: "EVER/USDT",
  },
  [DEX_TON_SURF]: {
    endpoint: 'test.flex.everos.dev',
    configDebotAddress: '0:eb2dc83fe4d71291aa59917ec7dd3a4034673792cec26726c33366cee5f05337',// '0:1e3522ff315ac39f7eb2236832519ef51a84e9af2430b1669e577dd837037c25',
    authDebotId: EXT_AUTH_DEBOT,
    tradeDebotId: EXT_TRADE_DEBOT,
    assetsDebotId: EXT_ASSETS_DEBOT,
    recoverDebotId: RECOVER_DEBOT,
    updateDebotId: EXT_UPDATE_DEBOT,
    alias: 'devnet',
    fee: 1.0015,
    enableMarketMode: true,
    enableCustomKeys: true,
    pairsDisabled: ['USDC/USDT'],
    authDisabled: false,
    defaultPairTicker: "EVER/TSDT",
  },
  [TRADE_FLEXDEX_FI]: { //ELECTRON MIRROR with NO AUTH
    endpoint: 'mainnet.evercloud.dev/9032ecfe98924075ae833da11735b480',
    configDebotAddress: '0:eb2dc83fe4d71291aa59917ec7dd3a4034673792cec26726c33366cee5f05337',
    authDebotId: EXT_AUTH_DEBOT,
    tradeDebotId: EXT_TRADE_DEBOT,
    assetsDebotId: EXT_ASSETS_DEBOT,
    recoverDebotId: RECOVER_DEBOT,
    alias: 'mainnet',
    fee: 1.0015,
    enableMarketMode: true,
    enableCustomKeys: true,
    pairsDisabled: ['USDC/USDT'],
    authDisabled: false,
    defaultPairTicker: "EVER/USDT",
  },
  [STAGE_FLEXDEX_FI]: { //EX-TRADE-FLEXDEX-FI
   // endpoint: 'devnet.evercloud.dev/9032ecfe98924075ae833da11735b480',
    endpoint:  'mainnet.evercloud.dev/9032ecfe98924075ae833da11735b480',
    configDebotAddress: '0:eb2dc83fe4d71291aa59917ec7dd3a4034673792cec26726c33366cee5f05337',
    authDebotId: EXT_AUTH_DEBOT,
    tradeDebotId: EXT_TRADE_DEBOT,
    assetsDebotId: EXT_ASSETS_DEBOT,
    recoverDebotId: RECOVER_DEBOT,
    alias: 'mainnet',
    fee: 1.0015,
    enableMarketMode: true,
    enableCustomKeys: true,
    pairsDisabled: ['USDC/USDT'],
    authDisabled: false,
    defaultPairTicker: "EVER/USDT",
  }
}

export const gotoDownloads = () => {
  window.open(DOWNLOADS_URL, '_blank');
}

export const getConfigByDomain = () => {
  if (ElectronUtils.isElectron) {
    return configs[ELECTRON];
  }
  const path = window.location.origin;
  if (path.includes(FLEX_TON_LIVE)) return configs[STAGE_FLEXDEX_FI];
  if (path.includes(STAGE_FLEXDEX_FI)) return configs[STAGE_FLEXDEX_FI];
  if (path.includes(TRADE_FLEXDEX_FI)) return configs[TRADE_FLEXDEX_FI];
  if (path.includes(DEX_TON_SURF)) return configs[DEX_TON_SURF];
  return configs[DEX_TON_SURF];
}

const DOWNLOADS_URL = 'https://flexdex.fi/downloads/';
export const ENDPOINT = getConfigByDomain().endpoint;

export const Debots:Map<string, Debot> = new Map([
  ['auth', {
    title: 'auth',
    name: ENDPOINT,
    alias: getConfigByDomain().alias,
    address: getConfigByDomain().authDebot,
  }],
  ['update', {
    title: 'update',
    name: ENDPOINT,
    alias: getConfigByDomain().alias,
    address: getConfigByDomain().updateDebot,
  }],
  ['trade', {
    title: 'trade',
    name: ENDPOINT,
    alias: getConfigByDomain().alias,
    address: getConfigByDomain().tradeDebot,
  }],
  ['assets', {
    title: 'assets',
    name: ENDPOINT,
    alias: getConfigByDomain().alias,
    address: getConfigByDomain().assetsDebot,
  }],
  ['recover', {
    title: 'recover',
    name: ENDPOINT,
    alias: getConfigByDomain().alias,
    address: getConfigByDomain().recoverDebot,
  }],
  ['testSign', {
    title: 'testSign',
    name: ENDPOINT,
    alias: 'devnet',
    address: '0:e34ca8f31bdc3da5b9b6dd58f4ed42177d45fe25a2b92c0c6c1edb0be6dabd70',
  }],
  ['configDebot', {
    title: 'configDebot',
    name: ENDPOINT,
    alias: 'devnet',
    address: getConfigByDomain().configDebotAddress,
  }],
]);

const logger = console;

export default class DebotClient {
  private configDebotBrowserHandle: Promise<BigInt>;

  public static authBrowserHandle: Promise<BigInt>;
  public static updateBrowserHandle: Promise<BigInt>;
  public static tradeBrowserHandle: Promise<BigInt>;
  public static assetsBrowserHandle: Promise<BigInt>;
  public static recoverBrowserHandle: Promise<BigInt>;

  private testSignBrowserHandle: Promise<BigInt>;

  private sboxAuthHandle: number; // or string with public key
  private sboxUpdateHandle: number; // or string with public key
  private sboxAssetsHandle: number; // or string with public key
  private sboxRecoverHandle: number; // or string with public key
  private sboxTradeHandle: number; // or string with public key
  private sboxTestSignHandle: number; // or string with public key

  private extensionPubkey: any = null; // or string with public key
  static flexClient: string; // or string with public key
  static userId: string; // or string with public key

  static userSigningBox: any = null;

  private static instance = null;

  public static async get(): DebotClient {
    if (!DebotClient.instance) {
      DebotClient.instance = new DebotClient();
      await DebotClient.instance.init();
    }
    return DebotClient.instance;
  }

  async init () {
    console.log("client init...", Debots.get('configDebot')!.name, Debots.get('configDebot')!.address);
    this.configDebotBrowserHandle = (await browser).create_browser(
      Debots.get('configDebot')!.name,
      Debots.get('configDebot')!.address
    );

    Debots.get('auth')!.address = await this.getAuthDebotAddress();
    Debots.get('update')!.address = await this.getUpdateDebotAddress();
    Debots.get('assets')!.address = await this.getAssetsDebotAddress();
    Debots.get('recover')!.address = await this.getRecoverDebotAddress();
    Debots.get('trade')!.address = await this.getTradeDebotAddress();

    this.authBrowserHandle = (await browser).create_browser(Debots.get('auth')!.name, Debots.get('auth')!.address);
    this.updateBrowserHandle = (await browser).create_browser(Debots.get('update')!.name, Debots.get('update')!.address);
    this.assetsBrowserHandle = (await browser).create_browser(Debots.get('assets')!.name, Debots.get('assets')!.address);
    this.recoverBrowserHandle = (await browser).create_browser(Debots.get('recover')!.name, Debots.get('recover')!.address);
    this.tradeBrowserHandle = (await browser).create_browser(Debots.get('trade')!.name, Debots.get('trade')!.address);

    console.log("client init... ready");
  }

  async getExtension() {
    console.log('await hasSurfKeeperProvider()...');
    const hasSurf = await hasSurfKeeperProvider();
    console.log('await hasSurfKeeperProvider()...', hasSurf);

    if (hasSurf) {
      //console.log('check connection...');
      this.extensionConnected = await rpc.connectStatus();
      //console.log('check connection... =>', this.extensionConnected);
      if (this.extensionConnected.isConnected) {
        if (!this.userSigningBox) await this.updateSignbox(this.extensionConnected);
        return this.extensionConnected;
      }
      this.extensionConnected = await rpc.connect();
      if (!this.extensionConnected.isConnected) return false;
      await this.updateSignbox(this.extensionConnected);
      return this.extensionConnected;
    }
    return false;
  }

  async disconnect() {
    console.log('disconnect...');
    this.extensionConnected = await rpc.connectStatus();
    if (this.extensionConnected.isConnected) {
      const result = await rpc.disconnect();
      console.log('disconnect... ready', result);
    }
  }

  /**
   * Update client with signing box
   **/
  async updateSignbox (extensionInfo: any): Promise<void[]> {
    console.log('updateSignbox with => ', extensionInfo);
    if (!extensionInfo.publicKey) throw 'No keys. Extension isn\'t connected. Check you don\'t use it in tracking mode.';
    this.extensionPubkey = extensionInfo.publicKey;

    this.userSigningBox = {};
    this.userSigningBox.get_public_key = async () => {
      console.log('get_public_key...', extensionInfo.publicKey);
      return extensionInfo.publicKey;
    }

    this.userSigningBox.sign = async (unsigned: any) => {
      console.log('sign with rpc, keys... =>', this.extensionPubkey);
      const res = await rpc.signData({
        data: Buffer.from(unsigned).toString('base64'),
      });
      console.log('signed, res.signature => ', res);
      return res.signature;
      /*);
      const res = await window.surfkeeper.request({
        method: 'signData',
        params: {
          data: Buffer.from(unsigned).toString('base64'),
        },
      });
      console.log('signed, res.signature => ', res.signature);
      return res.signature;*/
    }

    this.sboxUpdateHandle = await (await browser).register_signing_box(
      (await this.updateBrowserHandle),
      this.userSigningBox
    );

    this.sboxAuthHandle = await (await browser).register_signing_box(
      (await this.authBrowserHandle),
      this.userSigningBox
    );

    this.sboxAssetsHandle = await (await browser).register_signing_box(
      (await this.assetsBrowserHandle),
      this.userSigningBox
    );
    this.sboxRecoverHandle = await (await browser).register_signing_box(
      (await this.recoverBrowserHandle),
      this.userSigningBox
    );
    this.sboxTradeHandle = await (await browser).register_signing_box(
      (await this.tradeBrowserHandle),
      this.userSigningBox
    );

    return [
      await (await browser).update_user_settings((await this.updateBrowserHandle), {
        wallet: extensionInfo.address,
        pubkey: `0x${extensionInfo.publicKey}`,
        signing_box: this.sboxUpdateHandle
      }),
      await (await browser).update_user_settings((await this.authBrowserHandle), {
        wallet: extensionInfo.address,
        pubkey: `0x${extensionInfo.publicKey}`,
        signing_box: this.sboxAuthHandle
      }),
      await (await browser).update_user_settings((await this.assetsBrowserHandle), {
        wallet: extensionInfo.address,
        pubkey: `0x${extensionInfo.publicKey}`,
        signing_box: this.sboxAssetsHandle
      }),
      await (await browser).update_user_settings((await this.recoverBrowserHandle), {
        wallet: extensionInfo.address,
        pubkey: `0x${extensionInfo.publicKey}`,
        signing_box: this.sboxRecoverHandle
      }),
      await (await browser).update_user_settings((await this.tradeBrowserHandle), {
        wallet: extensionInfo.address,
        pubkey: `0x${extensionInfo.publicKey}`,
        signing_box: this.sboxTradeHandle
      }),
    ];
  }

  //config manifest
  private buildConfigDebotManifest (
    func: any,
    args: any
  ): JSON {
    return JSON.parse(`{
      "version": 0,
      "debotAddress": "${Debots.get('configDebot')!.address}",
      "initMethod": "${func}",
      "initArgs": ${args},
      "quiet": true,
      "autoApprove": ["ApproveOnChainCall"],
      "chain": [],
      "abi": ${callback.configDebotAbi}
    }`);
  };

  //config debot
  async getAssetsDebotAddress(): Promise<any> {
    try {
      logger.log(`Calling getAssetsDebotAddress...\n`);
      const manifest = this.buildConfigDebotManifest("getAddress",`{"i":"${getConfigByDomain().assetsDebotId}"}`);
      console.log(manifest)
      const debot = await (await browser).run_browser((await this.configDebotBrowserHandle), manifest);
      console.log('result assets debot => ', debot);
      return debot.res;
    } catch (err) {
      console.error(err);
    }
  }

  //config debot
  async getRecoverDebotAddress(): Promise<any> {
    try {
      logger.log(`Calling getRecoverDebotAddress...\n`);
      const manifest = this.buildConfigDebotManifest("getAddress",`{"i":"${getConfigByDomain().recoverDebotId}"}`);
      console.log(manifest)
      const debot = await (await browser).run_browser((await this.configDebotBrowserHandle), manifest);
      console.log('result recover debot => ', debot);
      return debot.res;
    } catch (err) {
      console.error(err);
    }
  }

  //config debot
  async getUpdateDebotAddress(): Promise<any> {
    try {
      logger.log(`Calling getUpdateDebotAddress...\n`);
      const manifest = this.buildConfigDebotManifest("getAddress",`{"i":"${getConfigByDomain().updateDebotId}"}`);
      console.log(manifest)
      const debot = await (await browser).run_browser((await this.configDebotBrowserHandle), manifest);
      console.log('result update debot => ', debot);
      return debot.res;
    } catch (err) {
      console.error(err);
    }
  }

  //config debot
  async getAuthDebotAddress(): Promise<any> {
    try {
      logger.log(`Calling getAuthDebotAddress...\n`);
      const manifest = this.buildConfigDebotManifest("getAddress",`{"i":"${getConfigByDomain().authDebotId}"}`);
      console.log(manifest)
      const debot = await (await browser).run_browser((await this.configDebotBrowserHandle), manifest);
      console.log('result auth debot => ', debot);
      return debot.res;
    } catch (err) {
      console.error(err);
    }
  }

  //config debot
  async getTradeDebotAddress(): Promise<any> {
    try {
      logger.log(`Calling getTradeDebotAddress...\n`);
      const manifest = this.buildConfigDebotManifest("getAddress",`{"i":"${getConfigByDomain().tradeDebotId}"}`);
      console.log(manifest)
      const debot = await (await browser).run_browser((await this.configDebotBrowserHandle), manifest);
      console.log('result trade debot => ', debot);
      return debot.res;
    } catch (err) {
      console.error(err);
    }
  }

  /*async send2giver(): Promise<any> {
    try {
      await this.updateApiBrowser();
      logger.log(`Calling send2giver...\n`);
      const manifest = this.buildTestSignManifest("send2giver", "{}");// `{"pubkey": "0x${await this.extensionPubkey}", "flexClient": "${this.flexClient}", "userId": "${this.userId}", "tradingPair": "${tradingPair}", "sell": "${sell}", "amount": "${amount}"}`)
      console.log(manifest)
      const testSignResult = await (await browser).run_browser((await this.testSignBrowserHandle), manifest);
      console.log('testSignResult => ', testSignResult);
      return testSignResult;
    } catch (err) {
      console.error(err);
    }
  }*/
}
